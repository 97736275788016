<template>
  <div
    v-if="isVisible"
    class="paragraph-teaser h-full"
    :class="[
      { container: hasContainer, [marginClasses]: hasNoParent },
      globalClasses,
    ]"
  >
    <template v-if="to && linkText">
      <TeaserTwoColumns
        v-if="!parentType && body"
        :title="title"
        :url="to"
        :image="teaserImage"
        :button-text="linkText"
        :body="body"
        :style-name="'teaser_half'"
      />
      <TeaserTextVertical
        v-else-if="teaserStyle === 'text_vertical'"
        :title="titleText"
        :url="to"
        :image="teaserImage"
        :button-text="linkText"
        :style-name="imageStyle"
      />
      <TeaserBox
        v-else-if="teaserStyle === 'box'"
        :title="titleText"
        :url="to"
        :image="teaserImage"
        :button-text="linkText"
        :style-name="imageStyle"
      />
      <TeaserTextBelow
        v-else-if="teaserStyle === 'text_below'"
        :title="linkText"
        :url="to"
        :image="teaserImage"
        :style-name="imageStyle"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type {
  MediaImageFragment,
  ParagraphTeaserEntityFragment,
  ParagraphTeaserFragment,
} from '#graphql-operations'
import type { LegacyImageStyle } from '~/helpers/imageStyles'

const { parentType, options, isEditing } = defineBlokkli({
  bundle: 'teaser',
  globalOptions: ['region', 'marginTop'],
  options: {
    display: {
      type: 'radios',
      label: 'Display',
      options: {
        reduced: 'Reduced',
        full: 'Full',
      },
      default: 'reduced',
    },
  },
  editor: {
    previewWidth: 512,
    editTitle: (el) =>
      (el.querySelector('h2') || el.querySelector('h3'))?.innerText,
  },
})

const { isVisible, marginClasses, globalClasses } =
  useMappedParagraphOptions(options)

type TeaserType = 'text_vertical' | 'box' | 'text_below' | 'text_right'

/**
 * This can be provided by parent paragraphs to suggest an image style.
 */
const suggestedImageStyle = inject<ComputedRef<LegacyImageStyle> | null>(
  'imageStyle',
  null,
)

/**
 * Provided by the slider paragraph.
 */
const sliderDisplay = inject<ComputedRef<'hero' | 'columns'> | null>(
  'sliderDisplay',
  null,
)

const imageStyle = computed(() => suggestedImageStyle?.value)

const props = defineProps<{
  link?: ParagraphTeaserFragment['link']
  linkText?: ParagraphTeaserFragment['linkText']
  title?: ParagraphTeaserFragment['title']
  image?: ParagraphTeaserFragment['image']
  body?: ParagraphTeaserFragment['body']
}>()

const entity = computed<ParagraphTeaserEntityFragment | null | undefined>(
  () => {
    const url = props.link?.uri
    if (url && 'entity' in url) {
      return url.entity
    }
    return null
  },
)

const teaserImage = computed<MediaImageFragment | undefined>(() => {
  if (entity.value && 'image' in entity.value && entity.value.image) {
    return entity.value.image
  }

  return props.image
})

const linkText = computed(
  () => props.linkText || entity.value?.label || props.title,
)
const titleText = computed(() => props.title || entity.value?.label)
const to = computed(() => props.link?.uri?.path)
const hasContainer = computed(
  () => !parentType.value && options.value.display !== 'full',
)
const hasNoParent = computed(() => !parentType.value)
const teaserStyle = computed<TeaserType>(() => {
  if (sliderDisplay?.value && !isEditing) {
    if (sliderDisplay.value === 'hero') {
      return 'text_vertical'
    }

    return 'text_below'
  }

  if (!parentType.value && options.value.display === 'full') {
    return 'text_vertical'
  }

  if (
    parentType.value === 'two_columns' ||
    (options.value.display === 'full' && parentType.value === 'grid')
  ) {
    return 'box'
  }

  return 'text_below'
})
</script>

<style lang="postcss">
.gradient-background {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.25), transparent 40%);
}
.slider .content-teaser,
.is-full_width .content-teaser {
  img {
    /* @apply max-h-[90vh]; */
  }
}
.content-teaser {
  /* @apply h-full; */
  /* img { */
  /*   @apply h-full object-cover; */
  /* } */
}
</style>
