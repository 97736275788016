<template>
  <DrupalLink :to="url" class="block relative h-full">
    <MediaImage
      v-if="image"
      v-bind="image"
      :style-name="styleName"
      class="aspect-[2/1]"
    />
    <div
      class="px-30 md:px-80 lg:px-100 absolute z-0 inset-0 w-full gradient-background"
    >
      <h2 v-if="title" class="is-2 absolute top-40 lg:top-80">
        <span class="vertical-text text-white">{{ title }}</span>
      </h2>
      <div class="absolute bottom-130 lg:bottom-80">
        <div :to="url" class="btn block">{{ buttonText }}</div>
      </div>
      <div
        v-if="caption"
        class="absolute md:right-100 bottom-40 text-white line-clamp-1"
        v-html="caption"
      />
    </div>
  </DrupalLink>
</template>

<script lang="ts" setup>
import type { MediaImageFragment } from '#graphql-operations'
import type { LegacyImageStyle } from '~/helpers/imageStyles'

const props = withDefaults(
  defineProps<{
    title?: string
    url: string
    image?: MediaImageFragment
    styleName?: LegacyImageStyle
    buttonText?: string
  }>(),
  {
    styleName: 'content_fullwidth',
    title: undefined,
    image: undefined,
    buttonText: undefined,
  },
)

const caption = computed(() => props.image?.caption)

defineOptions({
  name: 'TeaserTextVertical',
})
</script>
