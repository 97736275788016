<template>
  <iframe style="border: none; width: 100%; min-height: 650px" :src="src" />
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()

const src = computed(
  () =>
    `https://brandwidgets.outtra.com/brand-widget/exped/shop-map/?outtrabrandId=106&language=${language.value}`,
)
</script>
