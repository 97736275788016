<template>
  <div
    v-if="isVisible"
    :class="[
      {
        container: !parentType,
        [marginClasses]: !parentType,
        'not-first:mt-30': parentType,
      },
      globalClasses,
    ]"
  >
    <div
      class="flex gap-20"
      :class="{ 'flex-col': options.direction === 'horizontal' }"
    >
      <FieldItemTypeLink
        v-for="(link, i) in links"
        :key="i"
        v-bind="link"
        class="inline-block"
        :class="
          options.display === 'link'
            ? 'arrow-icon-link icon-link link'
            : 'btn is-outline'
        "
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphLinksFragment } from '#build/graphql-operations'

const { parentType, options } = defineBlokkli({
  bundle: 'links',
  globalOptions: ['region', 'marginTop'],
  options: {
    display: {
      type: 'radios',
      label: 'Display',
      options: {
        link: 'Link',
        button: 'Button',
      },
      default: 'link',
    },
    direction: {
      type: 'radios',
      label: 'Direction',
      options: {
        horizontal: 'Horizontal',
        vertical: 'Vertikal',
      },
      default: 'vertical',
    },
  },
  editor: {
    editTitle: (el) => {
      return el.querySelector('a')?.innerText
    },
  },
})

const { isVisible, globalClasses, marginClasses } =
  useMappedParagraphOptions(options)

defineProps<{
  links?: ParagraphLinksFragment['links']
}>()
</script>
