import type { DefineImageStyleConfig } from '#rokka/types'

type CropType = 'cover' | 'contain'

type ExpedImageStyleConfig = DefineImageStyleConfig & {
  /**
   * The crop type to apply.
   *
   * `'cover'`: Fills the image in the available box using a focal point.
   * `'contain'`: Does not crop the image but instead centers it in the available box.
   */
  cropType?: CropType
}

export function defineExpedImageStyle(
  config: ExpedImageStyleConfig,
): DefineImageStyleConfig {
  // Set the stacks to use. These stacks result in images of the same size as the default stack, however:
  // - They apply a solid white background on the image so we don't generate large images with alpha channels.
  // - In case of cropping, instead of filling the box using focal point, they don't crop the image at all an instead center them in the available box.
  const stacks: DefineImageStyleConfig['stacks'] =
    config.cropType === 'contain'
      ? {
          crop: 'fe_nuxt_crop_product',
          noCrop: 'fe_nuxt_no_crop_product',
        }
      : undefined

  return defineImageStyle({
    ...config,
    stacks,
  })
}
