<template>
  <div class="bg-gray p-10 flex flex-col justify-between">
    <div class="text-3xl font-bold">{{ options.position || '0' }}</div>
    <div class="font-bold" v-html="description" />
  </div>
</template>

<script lang="ts" setup>
// This paragraph is only ever rendered during editing.
import type { ParagraphPgTeaserFragment } from '#build/graphql-operations'

const { options } = defineBlokkli({
  bundle: 'pg_teaser',
  globalOptions: ['region'],
  options: {
    position: {
      type: 'text',
      label: 'Position',
      inputType: 'number',
      default: '',
    },
  },
})

defineProps<{
  title?: string
  description?: string
  link?: ParagraphPgTeaserFragment['link']
  image?: ParagraphPgTeaserFragment['image']
}>()
</script>
