<template>
  <DrupalLink
    v-if="url"
    :to="url"
    class="link icon-link max-w-full"
    target="_blank"
  >
    <ResponsiveSpriteSymbol name="download" />
    <span class="truncate">{{ name }}</span>
  </DrupalLink>
</template>

<script lang="ts" setup>
import type { MediaDocumentFragment } from '#build/graphql-operations'
import DrupalLink from '~/components/DrupalLink/index.vue'

const props = defineProps<{
  name?: string
  fileUrl?: MediaDocumentFragment['fileUrl']
}>()

const url = computed(() => {
  const path = props.fileUrl?.path
  if (!path) {
    return
  }

  // Replace rokka url with file_stack stack, otherwise xlsx cannot be
  // downloaded.
  if (path.includes('dynamic/noop')) {
    return path.replace('dynamic/noop', 'file_stack')
  }
  return path
})
</script>
