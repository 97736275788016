<template>
  <slot v-if="items.length" :items="items" />
</template>

<script lang="ts" setup>
/**
 * Handles the parsing and formatting of product and product variation fields.
 *
 * The component does not actually render anything, it only provides the mapped
 * field items to its default slot.
 */
import type {
  AllProductTypeSpecs,
  AllProductVariationTypes,
  PossibleSpecFieldName,
} from '~/layers/product/types'
import type {
  ProductFieldConfigFragment,
  ProductFragment,
} from '#build/graphql-operations'
import { falsy } from '~/helpers/type'

const props = defineProps<{
  /**
   * The product to get the field values for.
   */
  product: ProductFragment & AllProductTypeSpecs

  /**
   * The variations to get the field values for.
   */
  variations: AllProductVariationTypes[]

  /**
   * The field config.
   */
  fieldConfig: Record<string, ProductFieldConfigFragment>

  /**
   * The method used to check if a field name should be used.
   *
   * We use this to show different fields grouped (e.g. specifications,
   * material).
   */
  fieldFilter?: (fieldName: PossibleSpecFieldName) => boolean
}>()

const { $texts } = useNuxtApp()
const { isImperial } = useCountry()
const { mapValue, valuesToArray } = useProductSpecs()

/**
 * Get the options for formatting a field.
 */
function getOptions(
  fieldName: PossibleSpecFieldName,
): MapProductSpecOptions | undefined {
  if (fieldName.includes('temp')) {
    return {
      temperature: true,
    }
  } else if (fieldName.includes('person')) {
    return {
      people: true,
    }
  } else if (
    fieldName.includes('backlength_range') ||
    fieldName.includes('hipbelt_size') ||
    fieldName.includes('body_size')
  ) {
    return {
      length: true,
    }
  } else if (fieldName.includes('onesize')) {
    return {
      booleanValues: [$texts('product.valueOneSize', 'one size'), ''],
    }
  }
}

/**
 * Callback to filter the field.
 */
const filterField = (v: PossibleSpecFieldName, prefix: string) => {
  // Call the user provided filter function.
  if (props.fieldFilter && !props.fieldFilter(v)) {
    return
  }

  // Require the defined prefix.
  if (!v.startsWith(prefix)) {
    return
  }

  // If is imperial the field may not contain _mtr.
  if (isImperial.value) {
    return !v.includes('_mtr')
  }

  // Not imperial, so the field may not contain _imp.
  return !v.includes('_imp')
}

const productFields = computed<Array<keyof AllProductTypeSpecs>>(() =>
  // All field names that contain "field_pt" are considered product fields.
  (Object.keys(props.product) as Array<keyof AllProductTypeSpecs>).filter((v) =>
    filterField(v, 'field_pt'),
  ),
)

const variationFields = computed<Array<keyof AllProductVariationTypes>>(() =>
  // All field names that contain "field_pv" are considered product variation fields.
  (
    Object.keys(props.variations[0] || {}) as Array<
      keyof AllProductVariationTypes
    >
  ).filter((v) => filterField(v, 'field_pv')),
)

const mapVariationField = (field: keyof AllProductVariationTypes) => {
  const items = props.variations
    .map((variation) => {
      const fieldValue = variation[field]

      // Build the final value string displayed for this variation.
      const joinedValues: string = valuesToArray(fieldValue)
        .map((v) => mapValue(v, getOptions(field)))
        .filter(falsy)
        .join(', ')

      return {
        variation,
        value: joinedValues,
      }
    })
    .filter(falsy)

  // Array is empty, return.
  if (!items.length) {
    return
  }

  // Check if every value string is the same. In this case we just return the
  // single value without prefixing it by size.
  const firstValue = items[0].value
  const allAreEqual = items.every((v) => v.value === firstValue)
  if (allAreEqual) {
    return firstValue
  }

  // Return the list of values, with each value prefixed by the variation size.
  return items
    .map((v) => {
      const prefix = v.variation.size?.name || v.variation.color?.name
      if (prefix) {
        return `${prefix}: ${v.value}`
      }
      return v.value
    })
    .filter(onlyUnique)
    .join('<br>')
}

const mapProductField = (field: keyof AllProductTypeSpecs) =>
  valuesToArray(
    // Get the field value.
    props.product?.[field],
  )
    // Maps each field value.
    .map((v) => mapValue(v, getOptions(field)))
    // Remove undefined/empty values.
    .filter(falsy)
    .join('<br>')

type MappedItem = {
  title: string
  text?: string
  field: string
  weight: number
}

const items = computed<MappedItem[]>(() => {
  const product: MappedItem[] = productFields.value.map((field) => ({
    title: props.fieldConfig[field]?.label,
    weight: props.fieldConfig[field]?.weight || 9999,
    text: mapProductField(field),
    field,
  }))

  const variation: MappedItem[] = variationFields.value.map((field) => ({
    title: props.fieldConfig[field]?.label,
    weight: props.fieldConfig[field]?.weight || 9999,
    text: mapVariationField(field),
    field,
  }))

  return [...variation, ...product]
    .filter((v) => !!v.text)
    .sort((a, b) => a.weight - b.weight)
})
</script>

<script lang="ts">
export default {
  name: 'ProductSpecifications',
}
</script>
