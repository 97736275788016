<template>
  <div class="paragraph-product" :class="{ container: !parentType }">
    <template v-if="product">
      <ProductTeaser v-if="parentType" v-bind="product" />
      <ProductTeaserHero v-else v-bind="product" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphProductFragment } from '#graphql-operations'

const { parentType } = defineBlokkli({
  bundle: 'product',
  editor: {
    editTitle: (el) =>
      (el.querySelector('h2') || el.querySelector('h3'))?.innerText,
  },
})

defineProps<{
  product: ParagraphProductFragment['product']
}>()
</script>
