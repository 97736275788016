<template>
  <Tabs class="mt-30 md:mt-60 lg:mt-100 lg:min-h-[300px] container">
    <!-- SPECIFICATIONS -->
    <ProductSpecifications
      v-slot="{ items }"
      :product="product"
      :variations="product.variations as any"
      :field-config="fieldConfig"
      :field-filter="specFilter"
    >
      <TabsItem
        id="specs"
        :title="$texts('product.tagSpecifications', 'Specifications')"
        first
      >
        <div class="md:columns-3 lg:columns-4 xl:columns-5">
          <ProductGridItem
            v-for="item in items"
            :key="'specs' + item.field"
            :title="item.title"
            :text="item.text"
            :field="item.field"
            :weight="item.weight"
          />
        </div>
      </TabsItem>
    </ProductSpecifications>

    <!-- MATERIAL -->
    <ProductSpecifications
      v-slot="{ items }"
      :product="product"
      :variations="product.variations as any"
      :field-config="fieldConfig"
      :field-filter="materialFilter"
    >
      <TabsItem
        id="materials"
        :title="$texts('product.tabMaterial', 'Material')"
      >
        <div class="md:columns-3 lg:columns-4 xl:columns-5">
          <ProductGridItem
            v-for="item in items"
            :key="'material' + item.field"
            :title="item.title"
            :text="item.text"
            :field="item.field"
            :weight="item.weight"
          />
        </div>
      </TabsItem>
    </ProductSpecifications>

    <!-- DOWNLOADS -->
    <TabsItem
      v-if="product.downloads?.length"
      id="downloads"
      :title="$texts('product.tabDownloads', 'Downloads')"
    >
      <ul class="grid lg:grid-cols-3 gap-20">
        <li v-for="media in product.downloads" :key="'download' + media.name">
          <MediaDocument v-bind="media" />
        </li>
      </ul>
    </TabsItem>

    <!-- KNOWHOW -->
    <TabsItem
      v-if="product.knowHowNodes?.length"
      id="knowhow"
      :title="$texts('product.tabKnowhow', 'Knowhow')"
    >
      <ProductKnowHow :nodes="product.knowHowNodes" />
    </TabsItem>

    <!-- SUSTAINABILITY -->
    <TabsItem
      v-if="product.knowHowSustainabilityNodes || warrantyYears"
      id="sustainability"
      :title="$texts('product.tabSustainability', 'Sustainability')"
    >
      <ProductKnowHow :nodes="product.knowHowSustainabilityNodes">
        <ProductGridItem
          v-if="warrantyYears"
          :title="fieldConfig[WARRANTY_FIELD]?.label"
          :weight="fieldConfig[WARRANTY_FIELD]?.weight"
          :text="warrantyYears"
          :field="WARRANTY_FIELD"
        />
      </ProductKnowHow>
    </TabsItem>

    <!-- FAQ -->
    <TabsItem
      v-if="product.faqs?.length"
      id="faq"
      :title="$texts('product.tabFAQ', 'FAQ')"
    >
      <Accordion
        v-for="(item, i) in product.faqs"
        :key="'faq-' + i"
        :title="item.title || ''"
      >
        <div class="content pb-20 max-w-prose" v-html="item.body" />
      </Accordion>
    </TabsItem>

    <!-- DESIGN PRINCIPLES -->
    <TabsItem
      v-if="product.designPrinciples?.length"
      id="design-principles"
      :title="$texts('product.tabDesignPrinciples', 'Design principles')"
    >
      <ProductKnowHow :nodes="product.designPrinciples" />
    </TabsItem>
  </Tabs>
</template>

<script lang="ts" setup>
import type {
  ProductFieldConfigFragment,
  ProductFragment,
} from '#build/graphql-operations'
import type { PossibleSpecFieldName } from '~/layers/product/types'

const WARRANTY_FIELD = 'field_pt_shared_warranty_years'

const props = defineProps<{
  product: ProductFragment
}>()

const { $texts } = useNuxtApp()

const fieldConfig = computed<Record<string, ProductFieldConfigFragment>>(() =>
  (props.product.fieldConfig || []).reduce<
    Record<string, ProductFieldConfigFragment>
  >((acc, config) => {
    acc[config.name] = config
    return acc
  }, {}),
)

/**
 * Filter callback for fields that should be displayed in the "Specifications" tab.
 */
const specFilter = (fieldname: PossibleSpecFieldName) =>
  !materialFilter(fieldname) && fieldname !== WARRANTY_FIELD

/**
 * Filter callback for fields that should be displayed in the "Materials" tab.
 */
const materialFilter = (fieldname: PossibleSpecFieldName) =>
  fieldname.includes('_taxref_mat') && !fieldname.includes('mattech')

const warrantyYears = computed(() => {
  const v: number | null =
    WARRANTY_FIELD in props.product
      ? (props.product[WARRANTY_FIELD] as number)
      : null
  if (v) {
    const years = v.toString()
    return $texts('product.warrantyYearsText', '@years years').replace(
      '@years',
      years,
    )
  }

  return null
})
</script>
