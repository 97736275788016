<template>
  <div
    v-if="isVisible"
    class="paragraph-slider overflow-hidden"
    :class="[marginClasses, globalClasses]"
  >
    <div class="relative" :class="{ container: options.display !== 'hero' }">
      <BlokkliField
        v-slot="{ items }"
        :list="paragraphs"
        name="field_paragraphs"
        proxy-mode
      >
        <Slider
          v-if="options.display === 'hero'"
          :key="items[0]?.uuid"
          class="paragraph-slider-slider-hero max-w-edge mx-auto"
          pagination
        >
          <div
            v-for="(item, index) in items"
            :key="item.uuid"
            class="w-full mr-20"
          >
            <BlokkliItem
              :key="item.uuid"
              v-bind="item"
              :index="index"
              parent-type="slider"
            />
          </div>
        </Slider>

        <Slider
          v-else
          :key="items.map((v) => v.uuid).join('-')"
          :class="[
            { 'action-images-slider': options.display === 'action_images' },
            'paragraph-slider-slider-columns w-full',
          ]"
          arrows
          :panels-per-view="panelsPerView"
        >
          <div
            v-for="(item, index) in items"
            :key="item.uuid"
            class="mr-20 slider-item"
          >
            <BlokkliItem
              :key="item.uuid"
              v-bind="item"
              :index="index"
              parent-type="slider"
            />
          </div>
        </Slider>
      </BlokkliField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphSliderFragment } from '#graphql-operations'
import type { LegacyImageStyle } from '~/helpers/imageStyles'

const { isMobile } = useViewport()

const { options } = defineBlokkli({
  bundle: 'slider',
  globalOptions: ['region', 'marginTop'],
  options: {
    display: {
      type: 'radios',
      label: 'Display',
      options: {
        hero: 'Hero',
        columns: 'Columns',
        action_images: 'Action Images',
      },
      default: 'columns',
    },
  },

  editor: {
    disableEdit: true,
  },
})

const { isVisible, marginClasses, globalClasses } =
  useMappedParagraphOptions(options)

defineProps<{
  paragraphs?: ParagraphSliderFragment['paragraphs']
}>()

const sliderDisplay = computed(() => options.value.display)

provide('sliderDisplay', sliderDisplay)

const imageStyle = computed<LegacyImageStyle>(() => {
  if (options.value.display === 'hero') {
    return 'content_fullwidth'
  } else if (options.value.display === 'action_images') {
    return 'column_half_2_3'
  }

  return 'column_quarter'
})

provide('imageStyle', imageStyle)

const panelsPerView = computed(() => {
  if (options.value.display === 'hero') {
    return 1
  } else if (options.value.display === 'action_images') {
    return isMobile.value ? 1 : 1.5
  }
  return isMobile.value ? 1 : 3
})
</script>

<style lang="postcss">
.paragraph-slider-slider-hero {
  .paragraph-image {
    @apply max-h-[90vh];
    img {
      @apply max-h-[90vh] object-cover;
    }
  }
  .paragraph-teaser {
    @apply max-h-[90vh];
    img {
      @apply max-h-[90vh] object-cover;
    }
  }
}
</style>
