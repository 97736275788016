<template>
  <div
    v-if="isVisible"
    class="pg-document"
    :class="[
      globalClasses,
      marginClasses,
      {
        container: !parentType,
      },
    ]"
  >
    <media-document
      v-if="document?.fileUrl"
      :file-url="document.fileUrl"
      :name="document.name"
    />
    <div v-else-if="isEditing">Dokument konnte nicht geladen werden!</div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphDocumentFragment } from '#build/graphql-operations'

const { options, parentType, isEditing } = defineBlokkli({
  bundle: 'document',
  globalOptions: ['region', 'marginTop'],
})

const { isVisible, globalClasses, marginClasses } =
  useMappedParagraphOptions(options)

defineProps<{
  document?: ParagraphDocumentFragment['document']
}>()
</script>
