<template>
  <div v-if="isVisible" class="container" :class="[globalClasses]">
    <PageHeader :title="title" :lead="lead">
      <span v-blokkli-editable:field_title>{{ title }}</span>
      <template #lead>
        <span v-blokkli-editable:field_text>{{ lead }}</span>
      </template>
    </PageHeader>
  </div>
</template>

<script lang="ts" setup>
const { options } = defineBlokkli({
  bundle: 'page_header',
  globalOptions: ['region'],
  editor: {
    editTitle: (el) => el.querySelector('h1')?.innerText,
  },
})

const { isVisible, globalClasses } = useMappedParagraphOptions(options)

defineProps<{
  title?: string
  lead?: string
}>()
</script>
