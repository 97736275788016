<template>
  <div
    v-if="isVisible"
    class="container"
    :class="[globalClasses, marginClasses]"
  >
    <div :class="{ 'pointer-events-none': isEditing }">
      <Outtra v-if="options.widget === 'outtra'" />
      <Locally v-else-if="options.widget === 'locally'" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Outtra from './Outtra/index.vue'
import Locally from './Locally/index.vue'

const { options, isEditing } = defineBlokkli({
  bundle: 'widget',
  globalOptions: ['region', 'marginTop'],
  options: {
    widget: {
      type: 'radios',
      default: 'locally',
      label: 'Widget',
      options: {
        locally: 'Locally',
        outtra: 'Outtra',
      },
    },
  },
})

const { isVisible, globalClasses, marginClasses } =
  useMappedParagraphOptions(options)
</script>
