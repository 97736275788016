<template>
  <div v-if="url && src" class="aspect-[16/9] relative bg-gray">
    <a
      v-if="youtubeThumbnailUrl && !isPlaying"
      :href="url"
      class="absolute top-0 left-0 size-full group"
      @click.prevent="isPlaying = true"
    >
      <div
        v-if="!hideOverlay"
        class="bg-gradient-to-b from-black/50 to-black/0 absolute top-0 left-0 w-full text-white z-20 p-10 lg:p-20 font-bold text-xs md:text-sm lg:text-xl uppercase"
      >
        <h3>{{ name }}</h3>
      </div>
      <img
        :src="youtubeThumbnailUrl"
        class="relative size-full object-cover"
        loading="lazy"
      />
      <div
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-black/30 size-80 p-20 rounded-full transition group-hover:scale-110"
      >
        <div class="relative size-full">
          <SpriteSymbol
            name="play"
            class="absolute top-0 left-0 size-full translate-x-4 fill-white"
          />
        </div>
      </div>
    </a>
    <iframe
      v-else
      :src="src"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      class="absolute top-0 left-0 size-full"
    />
  </div>
</template>

<script lang="ts" setup>
import { getYouTubeID, getVimeoID, getYouTubeThumbnail } from '~/helpers/video'
import type { MediaRemoteVideoFragment } from '#graphql-operations'

const props = defineProps<{
  name?: MediaRemoteVideoFragment['name']
  url?: MediaRemoteVideoFragment['url']
  hideOverlay?: boolean
}>()

const isPlaying = ref(false)

const vimeoId = computed(() => getVimeoID(props.url))

const youtubeId = computed(() =>
  props.url ? getYouTubeID(props.url) : undefined,
)

const youtubeThumbnailUrl = computed(() => {
  if (youtubeId.value) {
    return getYouTubeThumbnail(youtubeId.value)
  }
  return ''
})

const src = computed<string | null>(() => {
  if (vimeoId.value) {
    const params = new URLSearchParams({
      byline: 'false',
      portrait: 'false',
      title: 'false',
      fun: 'false',
      dnt: 'true',
    })

    return `https://player.vimeo.com/video/${
      vimeoId.value
    }?${params.toString()}`
  } else if (youtubeId.value) {
    const params = new URLSearchParams()
    if (youtubeThumbnailUrl.value) {
      params.set('autoplay', '1')
    }
    return `https://www.youtube-nocookie.com/embed/${
      youtubeId.value
    }?${params.toString()}`
  }

  return null
})
</script>
