<template>
  <div
    v-if="isVisible"
    class="container mb-25"
    :class="[marginClasses, globalClasses]"
  >
    <div
      class="paragraph-three-columns grid gap-30 md:grid-cols-3"
      :class="{
        'items-start': options.alignment === 'top',
        'items-center': options.alignment === 'center',
        'items-end': options.alignment === 'bottom',
      }"
    >
      <BlokkliField :list="paragraphsOne" name="field_paragraphs" />
      <BlokkliField :list="paragraphsTwo" name="field_paragraphs_center" />
      <BlokkliField :list="paragraphsThree" name="field_paragraphs_aside" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphThreeColumnsFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphThreeColumns',
})

const { options } = defineBlokkli({
  bundle: 'three_columns',
  globalOptions: ['marginTop', 'region'],
  options: {
    alignment: {
      type: 'radios',
      label: 'Alignment',
      options: {
        top: 'Top',
        center: 'Center',
        bottom: 'Bottom',
      },
      default: 'top',
    },
  },

  editor: {
    disableEdit: true,
  },
})

const { isVisible, marginClasses, globalClasses } =
  useMappedParagraphOptions(options)

defineProps<{
  paragraphsOne?: ParagraphThreeColumnsFragment['paragraphsOne']
  paragraphsTwo?: ParagraphThreeColumnsFragment['paragraphsTwo']
  paragraphsThree?: ParagraphThreeColumnsFragment['paragraphsThree']
}>()

const imageStyle = computed(() => {
  return 'teaser_half'
})

provide('imageStyle', imageStyle)
</script>
