<template>
  <div
    v-if="isVisible"
    class="paragraph-slider overflow-hidden relative group"
    :class="[marginClasses, globalClasses]"
  >
    <div>
      <BlokkliField
        v-slot="{ items }"
        name="field_paragraphs"
        :list="paragraphs"
        proxy-mode
      >
        <div v-if="items.length" class="container-desktop 3xl:max-w-edge">
          <Slider
            :key="items.map((v) => v.uuid).join('-')"
            arrows
            :panels-per-view="panelsPerView"
          >
            <div
              v-for="(media, index) in getMedias(items)"
              :key="index + media.id!"
              class="w-full mr-30"
            >
              <MediaImage style-name="column_half" v-bind="media" />
              <div
                v-if="media.caption && showCaption"
                class="font-helvetica text-lg font-light mt-15"
                v-html="media.caption"
              />
            </div>
          </Slider>
        </div>
      </BlokkliField>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphSliderFragment } from '#graphql-operations'
import type { FieldListItem } from '#blokkli/types'
import {
  filterParagraphItemsByType,
  paragraphVisibleForRegion,
} from '~/helpers/paragraphs'
import { falsy } from '~/helpers/type'

const { isMobile } = useViewport()
const { region } = useCountry()

const { options } = defineBlokkli({
  bundle: 'gallery',
  globalOptions: ['region', 'marginTop'],
  options: {
    showCaption: {
      type: 'checkbox',
      label: 'Show caption',
      default: false,
    },
  },
  editor: {
    disableEdit: true,
  },
})

const showCaption = computed(() => options.value.showCaption)

const { isVisible, marginClasses, globalClasses } =
  useMappedParagraphOptions(options)

defineProps<{
  paragraphs?: ParagraphSliderFragment['paragraphs']
}>()

const imageStyle = computed(() => {
  return 'column_quarter'
})

provide('imageStyle', imageStyle)

const panelsPerView = computed(() => {
  return isMobile.value ? 1 : 3
})

function getMedias(items: FieldListItem[]) {
  return filterParagraphItemsByType(
    items.filter((v) => paragraphVisibleForRegion(v.options, region.value)),
    'image',
  )
    .map((v) => v.image)
    .filter(falsy)
}
</script>
