<template>
  <div
    v-if="isVisible"
    :id="uuid"
    :class="[{ container: !parentType }, globalClasses, marginClasses]"
    @click.capture="onClick"
    @keydown.enter.capture="onClick"
  >
    <h3 v-if="title" class="text-left font-bold mb-10 is-3">
      {{ title }}
    </h3>
    <figure v-if="structuredTable">
      <StructuredTable
        v-bind="structuredTable"
        :mobile-style="options.mobileStyle"
        :sticky="options.sticky"
        :equalize-widths="options.equalizeWidths"
      />
      <figcaption v-if="structuredTable.caption">
        {{ structuredTable.caption }}
      </figcaption>
    </figure>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphTableFragment } from '#graphql-operations'

const { onClick } = useClickTriggerProxy()

const { parentType, options, uuid } = defineBlokkli({
  bundle: 'table',
  globalOptions: ['region', 'marginTop'],
  options: {
    equalizeWidths: {
      type: 'checkbox',
      label: 'Equalize widths',
      default: false,
    },
    mobileStyle: {
      type: 'radios',
      label: 'Mobile Display',
      default: 'vertical',
      displayAs: 'icons',
      options: {
        vertical: {
          label: 'Vertical',
          icon: 'icon-blokkli-option-table-vertical',
        },
        horizontal: {
          label: 'Horizontal',
          icon: 'icon-blokkli-option-table-horizontal',
        },
      },
    },
    sticky: {
      type: 'radios',
      label: 'Sticky',
      default: 'left',
      options: {
        none: '-',
        left: 'Left',
        top: 'Top',
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('figcaption')?.innerText,
    determineVisibleOptions: (ctx) => {
      if (ctx.options.mobileStyle === 'vertical') {
        return ['region', 'marginTop', 'equalizeWidths', 'mobileStyle']
      }

      return ['region', 'marginTop', 'equalizeWidths', 'mobileStyle', 'sticky']
    },
  },
})

defineProps<{
  structuredTable: ParagraphTableFragment['structuredTable']
  title?: ParagraphTableFragment['title']
}>()

const { isVisible, marginClasses, globalClasses } =
  useMappedParagraphOptions(options)
</script>
