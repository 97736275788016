import type {
  ParagraphCategoryFragment,
  ParagraphDocumentFragment,
  ParagraphGalleryFragment,
  ParagraphGridFragment,
  ParagraphImageFragment,
  ParagraphLinksFragment,
  ParagraphPageHeaderFragment,
  ParagraphPersonFragment,
  ParagraphPgTeaserFragment,
  ParagraphProductFragment,
  ParagraphQuoteFragment,
  ParagraphRemoteVideoFragment,
  ParagraphSliderFragment,
  ParagraphTeaserFragment,
  ParagraphTextFragment,
  ParagraphTitleFragment,
  ParagraphTwoColumnsFragment,
  ParagraphWrikeWebformFragment,
} from '#build/graphql-operations'
import type { Region } from '~/settings'
import { falsy } from '../type'
import type { FieldListItem } from '#blokkli/types'

interface RegionAwareParagraphOptions {
  region?: string[] | undefined
  country?: string[]
}

type PossibleOptions = Record<string, any> & RegionAwareParagraphOptions

function getRegionOption(v: unknown): Region[] {
  if (typeof v === 'string') {
    return v.split(',').filter(Boolean) as Region[]
  } else if (Array.isArray(v)) {
    return v
  }

  return []
}

export function paragraphVisibleForRegion(
  options: PossibleOptions = {},
  currentRegion: Region,
) {
  const regions = getRegionOption(options.region)

  if (regions.length) {
    return regions.some((v) => v === currentRegion)
  }

  return true
}

type PropsTypeMap = {
  category: ParagraphCategoryFragment
  document: ParagraphDocumentFragment
  gallery: ParagraphGalleryFragment
  grid: ParagraphGridFragment
  image: ParagraphImageFragment
  links: ParagraphLinksFragment
  person: ParagraphPersonFragment
  page_header: ParagraphPageHeaderFragment
  pg_teaser: ParagraphPgTeaserFragment
  product: ParagraphProductFragment
  quote: ParagraphQuoteFragment
  remote_video: ParagraphRemoteVideoFragment
  slider: ParagraphSliderFragment
  teaser: ParagraphTeaserFragment
  text: ParagraphTextFragment
  title: ParagraphTitleFragment
  two_columns: ParagraphTwoColumnsFragment
  wrike_webform: ParagraphWrikeWebformFragment
  widget: undefined
}

export function filterParagraphItemsByType<T extends keyof PropsTypeMap>(
  items: FieldListItem[],
  bundle: T,
): Array<PropsTypeMap[T]> {
  return items
    .filter((v) => v.bundle === bundle)
    .map((v) => v.props)
    .filter(falsy) as Array<PropsTypeMap[T]>
}
