<template>
  <div v-if="shouldRender">
    <template v-if="product">
      <ProductDescription
        v-if="options.section === 'description'"
        :body="product.body"
        :sustainability="product.sustainabilityLabels"
        :awards="product.awards"
      />
      <ProductTabs v-else-if="options.section === 'tabs'" :product="product" />
      <ProductRelated
        v-else-if="options.section === 'related_accessories'"
        class="mt-50 lg:mt-80 xl:mt-130"
        :products="product.relatedAccessories"
        :title="$texts('product.relatedAccessories', 'Accessories')"
      />
      <ProductRelated
        v-else-if="options.section === 'related_products'"
        class="mt-50 lg:mt-80 xl:mt-130"
        :products="product.relatedProducts"
        :title="$texts('product.relatedProducts', 'Related Products')"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { ProductFragment } from '#build/graphql-operations'
import { INJECTION_PRODUCT_PAGE } from '~/helpers/symbols'
import type { AllProductTypeSpecs } from '~/layers/product/types'

/**
 * Renders product detail page sections.
 */
const { options, isEditing } = defineBlokkli({
  bundle: 'product_section',
  options: {
    section: {
      type: 'radios',
      default: '',
      label: 'Section',
      options: {
        description: 'Description',
        tabs: 'Tabs',
        related_accessories: 'Related Accessories',
        related_products: 'Related Products',
      },
    },
  },
  editor: {
    disableEdit: true,
  },
})

// Provided by ~/pages/products/[category]/[product]/index.vue
const product = inject<ComputedRef<ProductFragment & AllProductTypeSpecs>>(
  INJECTION_PRODUCT_PAGE,
)

const shouldRender = computed(() => {
  // We have to render something during editing.
  if (isEditing) {
    return true
  }

  // Product should always be injected unless someone removed it from the page
  // component.
  if (!product) {
    return false
  }

  if (options.value.section === 'related_products') {
    return !!product.value.relatedProducts?.length
  } else if (options.value.section === 'related_accessories') {
    return !!product.value.relatedAccessories?.length
  }

  // Asume we have content.
  return true
})
</script>
