import { paragraphVisibleForRegion } from '~/helpers/paragraphs'
import { INJECT_IS_EDITING } from '#blokkli/helpers/symbols'

interface RegionAwareParagraphOptions {
  region?: string[]
  marginTop?: string
}

/**
 * Returns the matching margin classes.
 */
function getMarginClasses(marginTop = ''): string {
  if (marginTop === 's') {
    return 'mt-10'
  } else if (marginTop === 'm') {
    return 'mt-20 md:mt-30'
  } else if (marginTop === 'l') {
    return 'mt-30 md:mt-60'
  } else if (marginTop === 'xl' || marginTop === '') {
    return 'mt-40 md:mt-100'
  } else if (marginTop === 'xxl') {
    return 'mt-40 md:mt-60 lg:mt-160'
  }

  return ''
}

/**
 * Returns shared paragraph options for margin classes and region visibility.
 */
export default function (
  options: ComputedRef<RegionAwareParagraphOptions>,
  index?: ComputedRef<number>,
) {
  const { region } = useCountry()

  // Provided via blokkli.
  const isEditing = inject(INJECT_IS_EDITING, false)

  // During editing the editor can switch between regions.
  // This is provided via this state variable.
  const regionOverride = isEditing
    ? useState<any>('blokkli_region_override', () => 'all')
    : null

  const isVisible = computed(() => {
    if (isEditing) {
      return true
    }

    return paragraphVisibleForRegion(options.value, region.value)
  })

  const marginClasses = computed(() => {
    if (index && index.value === 0) {
      return ''
    }
    return getMarginClasses(options.value.marginTop)
  })

  const globalClasses = computed<string>(() => {
    const classes: string[] = []
    if (isEditing) {
      if (options.value.region?.length) {
        classes.push('bk-paragraph-region-affected')
      }

      if (regionOverride?.value && regionOverride.value !== 'all') {
        const isVisibleWithOverride = paragraphVisibleForRegion(
          options.value,
          regionOverride.value,
        )
        if (!isVisibleWithOverride) {
          classes.push('bk-paragraph-region-hidden')
        }
      }
    }
    return classes.join(' ')
  })

  return { isVisible, globalClasses, marginClasses }
}
