import type {
  FieldItemPhysicalDimensionsFragment,
  FieldItemPhysicalMeasurementFragment,
} from '#build/graphql-operations'
import { falsy } from '~/helpers/type'
import type { PossibleSpecTypes } from '~/layers/product/types'

export function parseNumber(v?: number | string): string {
  if (!v || isNaN(parseFloat(v + ''))) return ''
  return Math.round(parseFloat(v + '') * 100) / 100 + ''
}

export function onlyUnique(
  value: string | number,
  index: number,
  self: Array<string | number>,
) {
  return self.indexOf(value) === index
}

export type MapProductSpecOptions = {
  /**
   * Whether to suffix the values with a length unit.
   */
  length?: boolean

  /**
   * Whether to suffix the values with a temperature unit.
   */
  temperature?: boolean

  /**
   * Whether to suffix the values with person/people.
   */
  people?: boolean

  /**
   * The range labels as [singular, plural].
   */
  rangeLabels?: [singular: string, plural: string]

  /**
   * The values to use for boolean values. Defaults to "Yes" and "No".
   */
  booleanValues?: [true: string, false?: string]
}

function mapRangeValue(
  from?: number | string,
  to?: number | string,
  rangeLabels?: [string, string],
  suffix = '',
) {
  if (from === undefined && to === undefined) {
    return
  }

  const range = [from, to].filter(falsy).filter(onlyUnique).join(' - ')

  const singular = rangeLabels?.[0] || ''
  const plural = rangeLabels?.[1] || ''

  if (from !== undefined && to !== undefined) {
    return `${range}${suffix} ${plural}`
  } else if (from === undefined && to !== undefined) {
    return `${to}${suffix} ${to === 1 ? singular : plural}`
  } else if (from !== undefined && to === undefined) {
    return `${from}${suffix} ${from === 1 ? singular : plural}`
  }

  if (suffix) {
    return range + suffix
  }

  return range
}

function mapPhysicalMeasurement(
  v: FieldItemPhysicalMeasurementFragment,
): string | undefined {
  if (v.unit && v.number !== undefined) {
    return `${parseNumber(v.number)} ${v.unit}`
  } else if (v.number) {
    return `${parseNumber(v.number)}`
  }
}

export function mapPhysicalDimensions(
  v: FieldItemPhysicalDimensionsFragment,
): string {
  let output: string = ''
  if (v.length && parseNumber(v.length) !== '0') {
    output += parseNumber(v.length)
  }
  if (v.width && parseNumber(v.length) !== '0') {
    output += (output.length ? ' x ' : '') + parseNumber(v.width)
  }
  if (v.height && parseNumber(v.height) !== '0') {
    output += (output.length ? ' x ' : '') + parseNumber(v.height)
  }
  if (v.unit && output !== '') {
    output += ' ' + v.unit
  }
  return output
}

export default function () {
  const { $texts } = useNuxtApp()
  const { isImperial } = useCountry()

  function mapValue(
    v: PossibleSpecTypes,
    options?: MapProductSpecOptions,
  ): string | undefined {
    if (v === undefined || v === null) {
      return
    }

    const getSuffix = (): string => {
      if (options?.temperature) {
        return isImperial.value ? ' °F' : ' °C'
      }
      if (options?.length) {
        return isImperial.value ? ' in' : ' cm'
      }

      return ''
    }

    const getRangeLabels = (): [string, string] | undefined => {
      if (options?.rangeLabels) {
        return options.rangeLabels
      } else if (options?.people) {
        return [
          $texts('product.unitPersonCapacitySingularText', 'Person'),
          $texts('product.unitPersonCapacityPluralText', 'People'),
        ]
      }
    }

    if (typeof v === 'number') {
      return v.toString() + getSuffix()
    } else if (typeof v === 'string') {
      return v + getSuffix()
    } else if (typeof v === 'boolean') {
      if (v === true) {
        return (
          options?.booleanValues?.[0] || $texts('product.valueYesText', 'Yes')
        )
      }
    } else if ('name' in v) {
      return v.name
    } else if ('from' in v) {
      const mapped =
        mapRangeValue(v.from, v.to, getRangeLabels(), getSuffix()) || ''
      // Replaces "0 - 200cm" with "200cm".
      return mapped.replace(/(^0 - )(\d)/, '< $2')
    } else if ('number' in v && 'unit' in v) {
      return mapPhysicalMeasurement(v)
    } else if ('width' in v && 'height' in v) {
      return mapPhysicalDimensions(v)
    }
  }

  function valuesToArray(
    metric?: PossibleSpecTypes | PossibleSpecTypes[] | undefined,
    imperial?: PossibleSpecTypes | PossibleSpecTypes[] | undefined,
  ): PossibleSpecTypes[] {
    // Use the imperial values if provided and the current region requires it.
    const toUse = isImperial.value && imperial ? imperial : metric

    // The valus is already an array, return.
    if (Array.isArray(toUse)) {
      return toUse
    }

    if (toUse === undefined) {
      return []
    }

    // Make an array out of the value.
    return [toUse]
  }

  return { mapValue, valuesToArray }
}
