<template>
  <div v-if="items.length">
    <h3 v-if="title" class="is-3 text-sm lg:text-2xl">
      {{ title }}
    </h3>
    <div class="flex flex-wrap -mx-10">
      <component
        :is="item.is"
        v-for="item in items"
        :key="item.name"
        v-bind="item.attrs"
        class="w-1/2 px-10 mb-20"
        target="_blank"
      >
        <MediaImage v-bind="item.media" style-name="product_teaser" />
      </component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  TaxonomyTermAwardLabelsFragment,
  TaxonomyTermSustainabilityLabelsFragment,
} from '#build/graphql-operations'
import { falsy } from '~/helpers/type'

const props = defineProps<{
  title?: string
  terms?:
    | TaxonomyTermSustainabilityLabelsFragment[]
    | TaxonomyTermAwardLabelsFragment[]
}>()

const items = computed(() => {
  return (
    props.terms
      ?.map((v) => {
        if (!v.image?.image) {
          return
        }
        const url = v.link?.url?.path
        return {
          is: url ? 'a' : 'div',
          name: v.name,
          attrs: url
            ? {
                href: url,
              }
            : {},
          media: v.image,
        }
      })
      .filter(falsy) || []
  )
})
</script>
