<template>
  <DrupalLink v-if="to" v-slot="{ isExternal }" :to="to">
    <slot :label="label" :is-external="isExternal">{{ label }}</slot>
  </DrupalLink>
</template>

<script lang="ts" setup>
import type {
  FieldItemTypeLinkEntityFragment,
  FieldItemTypeLinkFragment,
} from '#graphql-operations'

const props = defineProps<{
  title?: FieldItemTypeLinkFragment['title']
  uri?: FieldItemTypeLinkFragment['uri']
}>()

const entity = computed<FieldItemTypeLinkEntityFragment | undefined | null>(
  () => {
    if (props.uri && 'entity' in props.uri) {
      return props.uri.entity
    }
    return null
  },
)

const label = computed(() => props.title || entity.value?.title)
const to = computed(() => props.uri?.path)
</script>
